<template>
  <div class="order-examine-popup">
    <el-dialog title="【补录代垫费用】" :close-on-click-modal="false" :visible.sync="visible" width="30%" center>
      <el-form :model="dataForm" ref="dataForm" size="small" label-width="140px">
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="平台预付代垫" prop="platformPrepayment">
              <el-input v-model="dataForm.platformPrepayment" placeholder="平台预付代垫"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="司机预付代垫" prop="driverPrepayment">
              <el-input v-model="dataForm.driverPrepayment" placeholder="司机预付代垫"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">确 定</el-button>
        <el-button @click="visible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        platformPrepayment: null,
        driverPrepayment: null
      }
    }
  },
  methods: {
    submit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: this.$http.adornUrl(`/costInfo/inputOrderPrepayment`),
            method: 'POST',
            data: this.$http.adornData(this.dataForm)
          }).then(({ data }) => {
            if (data) {
              this.$message({
                message: '保存成功',
                type: 'success',
                duration: 1000,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    },
    init (orderInfo) {
      this.visible = true
      this.dataForm.id = orderInfo.id
      this.dataForm.platformPrepayment = orderInfo.platformPrepayment
      this.dataForm.driverPrepayment = orderInfo.driverPrepayment
    }
  }
}
</script>
